/**
 * @desc 重新计算删除若干条目后的页码数，防止删除一定数量的条目后当前页无条目展示
 * @param {Number} total，总条数
 * @param {Number} pageNo，当前页码数
 * @param {Number} pageSize，当前每页的条目数
 * @param {Number} removeCount，需要删除的条目数，默认值: 1
 * @returns {Number} 删除若干条目后的页码数
 */
export default (total, pageNo, pageSize, removeCount = 1) => {
  const remainingPageNo = Math.ceil((total - removeCount) / pageSize);

  return remainingPageNo < pageNo
    ? remainingPageNo < 1
      ? 1
      : remainingPageNo
    : pageNo;
};
