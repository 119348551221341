<template>
  <div class="tab">
    <ul class="tab-list">
      <li
        class="tab-item"
        :class="{ active: item[valueKey] === activeValue }"
        v-for="item in tabs"
        :key="item[valueKey]"
        @click="handleTabItemClick(item)"
      >
        <slot v-if="$scopedSlots.default" :item="item" />

        <div v-else>{{ item[labelKey] }}</div>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: 'tab',
    props: {
      // 选中的 value
      value: {
        type: [String, Number],
        default: '',
      },
      // tab 列表数据
      tabs: {
        type: Array,
        validator: val => val.length,
        required: true,
      },
      // 作为 label 的 key
      labelKey: {
        type: String,
        default: 'label',
      },
      // 作为 value 的 key
      valueKey: {
        type: String,
        default: 'value',
      },
    },
    data() {
      return {
        // 当前选中的 tab 项 value
        activeValue: '',
      };
    },
    methods: {
      // tab 项单击
      handleTabItemClick(item) {
        const oldValue = this.activeValue;
        const newValue = item[this.valueKey];

        this.$emit('on-item-click', item);

        if (newValue === oldValue) return;

        this.activeValue = newValue;

        this.$emit('input', newValue);
        this.$emit('on-change', newValue, oldValue);
      },
    },
    watch: {
      value: {
        immediate: true,
        handler(n) {
          if (n !== undefined) {
            this.activeValue = n;

            return;
          }

          // 不存在，则默认选中第一个 tab 项
          this.activeValue = this.tabs[0][this.valueKey];
        },
      },
    },
  };
</script>

<style scoped lang="scss">
  .tab {
    position: relative;

    &::after {
      content: ' ';
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 1px;
      background-color: #e4e7ed;
    }

    .tab-list {
      display: inline-flex;
      font-size: 13px;

      .tab-item {
        padding: 0 20px;
        border: 1px solid #e4e7ed;
        line-height: 32px;
        background-color: #fbfcfe;
        color: #666;
        cursor: pointer;
        transition: all 0.3s;

        &:nth-of-type(1) {
          border-top-left-radius: 4px;
        }

        &:nth-last-of-type(1) {
          border-top-right-radius: 4px;
        }

        & + .tab-item {
          margin-left: -1px;
        }

        &.active {
          position: relative;
          background-color: #fff;
          color: #409eff;

          &::after {
            content: ' ';
            position: absolute;
            bottom: -1px;
            left: 0;
            z-index: 2;
            width: 100%;
            height: 1px;
            background-color: #fff;
          }
        }
      }
    }
  }
</style>
