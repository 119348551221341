// 字典类型
export default {
  map: {
    1: '直播平台',
    2: '游戏版块',
    3: '游戏名称',
    4: '数值区间',
    5: '联系方式',
    6: '联系结果',
  },
  enum: {
    platform: 1,
    gameCategory: 2,
    gameName: 3,
    numberScatter: 4,
    contactType: 5,
    contactResult: 6,
  },
};
