<!-- sys-dictionary -->
<template>
  <div class="sys-dictionary">
    <!-- tab 列表 -->
    <div class="sys-dictionary-tab">
      <tab
        v-model="activeDictionaryType"
        :tabs="dictionaryTypeList"
        @on-change="handleTabActiveChange"
      />
    </div>

    <!-- 查询表单 -->
    <div class="sys-dictionary-form">
      <el-form :model="model" inline size="small" @submit.native.prevent>
        <el-form-item label="搜索:">
          <el-input
            class="form-search"
            v-model.trim="model.keyword"
            placeholder="字典值"
            clearable
            @keyup.native.enter="handleFormSearchClick"
          />
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="searching"
            @click="handleFormSearchClick"
            >查询
          </el-button>

          <el-button icon="el-icon-refresh" @click="handleFormResetClick"
            >重置
          </el-button>
        </el-form-item>
      </el-form>
    </div>

    <!-- 操作栏 -->
    <div class="sys-dictionary-operate">
      <el-button
        type="primary"
        icon="el-icon-plus"
        size="small"
        @click="handleOperateAddClick"
        >新增
      </el-button>

      <el-button
        type="danger"
        icon="el-icon-delete"
        size="small"
        :disabled="!removeIds.length"
        @click="handleOperateRemoveClick"
        >删除
      </el-button>
    </div>

    <!-- 数据表格 -->
    <div class="sys-dictionary-table">
      <el-table
        v-loading="loading"
        ref="table"
        :data="dictionaries"
        size="small"
        @selection-change="handleTableSelectionChange"
      >
        <el-table-column type="selection" width="55" />

        <el-table-column label="字典值" prop="name" />

        <el-table-column label="备注">
          <template v-slot="{ row }">
            <div>{{ row.remark || '-' }}</div>
          </template>
        </el-table-column>

        <el-table-column label="创建日期" prop="createTime" />

        <el-table-column label="操作" width="90px">
          <template v-slot="{ row }">
            <div class="table-row-operate">
              <el-button
                plain
                icon="el-icon-edit"
                circle
                size="mini"
                @click="handleTableRowEditClick(row)"
              />

              <el-button
                plain
                type="danger"
                icon="el-icon-delete"
                circle
                size="mini"
                @click="handleTableRowRemoveClick(row)"
              />
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 分页器 -->
    <div class="sys-dictionary-pagination">
      <el-pagination
        :total="total"
        :current-page="pageNo"
        :page-sizes="pageSizes"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @current-change="handlePageNoChange"
        @size-change="handlePageSizeChange"
      />
    </div>

    <!-- 新增/编辑对话框 -->
    <dictionary-edit
      v-model="showEditDialog"
      :id="operateId"
      :type="activeDictionaryType"
      @on-ok="handleEditDialogOkClick"
    />

    <!-- 删除对话框 -->
    <dictionary-remove
      v-model="showRemoveDialog"
      :ok-button-loading="removing"
      @on-close="handleRemoveDialogClose"
      @on-ok="handleRemoveDialogOkClick"
    />
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex';
  import Tab from '../../../components/tab';
  import { pageSize, pageSizes } from '../../../config/pagination';
  import recountPageNo from '../../../lib/recount-page-no';
  import dictionaryType from '../../../enum/dictionary-type';
  import { removeDictionaries } from '../../../api/sys/dictionary';

  export default {
    name: 'sys-dictionary',
    components: {
      Tab,
      DictionaryEdit: () =>
        import(/* webpackChunkName: 'dictionary-edit' */ './dictionary-edit'),
      DictionaryRemove: () =>
        import(
          /* webpackChunkName: 'dictionary-remove' */ '../../../components/confirm'
        ),
    },
    data() {
      return {
        // 当前激活的字典类型
        activeDictionaryType: 0,
        model: null,
        pageNo: 1,
        pageSize,
        pageSizes,
        operateId: '',
        showEditDialog: false,
        removeIds: [],
        showRemoveDialog: false,
        loading: false,
        searching: false,
        removing: false,
      };
    },
    computed: {
      ...mapState('sys/dictionary', ['dictionaries', 'total']),

      // 字典类型列表
      dictionaryTypeList() {
        const { map } = dictionaryType;

        return Object.entries(map).map(([value, key]) => ({
          label: key,
          value: +value,
        }));
      },
    },
    created() {
      this.initActiveTab();
      this.initModel();
    },
    mounted() {
      this.getTableData();
    },
    methods: {
      ...mapActions('sys/dictionary', ['actDictionaries']),

      // 初始化模型
      initModel() {
        this.model = {
          keyword: '',
        };
      },
      // 初始化激活的 tab
      initActiveTab() {
        this.activeDictionaryType = this.dictionaryTypeList[0]?.value;
      },
      // 获取表格数据
      async getTableData() {
        this.loading = true;

        const success = await this.actDictionaries({
          ...this.model,
          type: this.activeDictionaryType,
          pageNo: this.pageNo,
          pageSize: this.pageSize,
        });

        this.loading = false;

        return success;
      },
      // 删除表格数据
      async removeTableData() {
        const ids = this.removeIds;

        if (!ids.length) return;

        this.removing = true;

        const res = await removeDictionaries(ids);

        this.removing = false;

        return !!res;
      },
      // tab 激活项改变
      handleTabActiveChange() {
        this.pageNo = 1;

        this.initModel();
        this.getTableData();
      },
      // 表单查询按钮单击
      async handleFormSearchClick() {
        this.pageNo = 1;
        this.searching = true;

        await this.getTableData();

        this.searching = false;
      },
      // 表单重置按钮单击
      handleFormResetClick() {
        this.pageNo = 1;

        this.initModel();
        this.getTableData();
      },
      // 操作栏新增按钮单击
      handleOperateAddClick() {
        this.operateId = '';
        this.showEditDialog = true;
      },
      // 操作栏删除按钮单击
      handleOperateRemoveClick() {
        this.showRemoveDialog = true;
      },
      // 表格选择项改变
      handleTableSelectionChange(rows) {
        this.removeIds = rows.map(row => row._id);
      },
      // 表格行编辑按钮单击
      handleTableRowEditClick({ _id }) {
        this.operateId = _id;
        this.showEditDialog = true;
      },
      // 表格行删除按钮单击
      handleTableRowRemoveClick({ _id }) {
        this.removeIds = [_id];
        this.showRemoveDialog = true;
      },
      // 新增/编辑对话框确定按钮执行成功
      handleEditDialogOkClick() {
        this.getTableData();
      },
      // 删除对话框关闭
      handleRemoveDialogClose() {
        this.removeIds = [];

        this.$refs.table.clearSelection();
      },
      // 删除对话框确定按钮单击
      async handleRemoveDialogOkClick() {
        const success = await this.removeTableData();

        if (!success) return;

        this.showRemoveDialog = false;
        this.pageNo = recountPageNo(
          this.total,
          this.pageNo,
          this.pageSize,
          this.removeIds.length
        );

        this.getTableData();
      },
      // 分页器页码改变
      handlePageNoChange(pageNo) {
        this.pageNo = pageNo;

        this.getTableData();
      },
      // 分页器条目数改变
      handlePageSizeChange(pageSize) {
        this.pageSize = pageSize;

        this.getTableData();
      },
    },
  };
</script>

<style scoped lang="scss">
  .sys-dictionary {
    // 查询表单
    .sys-dictionary-form {
      margin-top: 20px;
    }

    // 数据表格
    .sys-dictionary-table {
      margin-top: 20px;

      // 操作按钮
      .table-row-operate {
        .el-button {
          min-width: auto;
        }
      }
    }

    // 分页器
    .sys-dictionary-pagination {
      margin-top: 20px;
    }
  }
</style>
